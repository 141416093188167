<template>
  <div>
    <!-- ========== HEADER ========== -->
    <header id="header">
      <b-navbar
        toggleable="lg"
        type="light"
        variant="faded"
        class="shadow-sm bg-white"
      >
        <b-container>
          <b-navbar-brand href="/exam">
            <img src="@/assets/images/logo/gytp.png" width="60" alt="logo">
            SuperStarT
          </b-navbar-brand>
        </b-container>
      </b-navbar>
    </header>
    <!-- ========== END HEADER ========== -->
    <!-- ========== EXAM ========== -->
    <div>
      <router-view />
    </div>
    <!-- ========== END EXAM ========== -->
  </div>
</template>
<script>
export default {
  name: 'Exam'
}
</script>
<style>
body {
  background-color: #f8f9fa !important;
}
</style>
